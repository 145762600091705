@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(#caf0f8, #023e8a);
  height: 100%;
  margin: 0;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.item-image {
  display: block;
  max-width: 40px;
  max-height: 40px;
  width: auto;
  height: auto;
}

.wishlistitem {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.wishlistitemimg {
  width: 40px;
  height: 40px;
}
